<script>
  import { get, call } from 'vuex-pathify'
  import { ApiError } from '@/api'
  import AddressForm from '@/components/account/AddressForm'

  export default {
    components: {
      AddressForm,
    },
    data() {
      return {
        formRef: null,
        addressId: parseInt(this.$route.params.addressId),
      }
    },
    computed: {
      ...get('account', ['addresses']),
      address() {
        return this.addresses?.find((address) => address.id === this.addressId)
      },
    },
    methods: {
      ...call('account', ['updateAddress']),
      async submit(address) {
        try {
          await this.formRef.process(this.updateAddress(address))
          this.$navigate({ name: 'AccountProfileSettings' })
        } catch (error) {
          if (error instanceof ApiError) {
            return
          }
          return Promise.reject(error)
        }
      },
    },
  }
</script>

<template>
  <section>
    <AddressForm v-ref="formRef" :edit-address="address" @submit="submit($event)">
      Edit Shipping Address
      <p class="text-sm font-normal leading-snug-tight mt-1">
        *If you are changing your shipping address midseason, please email customer support to
        confirm your box and/or order will be shipped to the correct address,
        <BaseLinkStyled href="mailto:help@alltrue.com">help@alltrue.com</BaseLinkStyled>.
      </p>
      <template v-slot:buttonLabel>Save Address</template>
    </AddressForm>

    <div class="mt-6 -mb-2 flex justify-center">
      <BaseLinkStyled :to="{ name: 'AccountProfileSettings' }" color="gray" font-size="xs">
        Cancel
      </BaseLinkStyled>
    </div>
  </section>
</template>
